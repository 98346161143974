import { CommonModule } from '@angular/common'
import { AfterViewInit, ChangeDetectionStrategy, Component, inject, model, OnDestroy, OnInit, output, signal, viewChild } from '@angular/core'
import { toObservable } from '@angular/core/rxjs-interop'
import { MatButton, MatIconButton } from '@angular/material/button'
import { MatRipple } from '@angular/material/core'
import { MatIcon } from '@angular/material/icon'
import { MatRadioButton } from '@angular/material/radio'

import { produce } from 'immer'
import { last } from 'lodash-es'
import { debounceTime, Subscription } from 'rxjs'

import { ScrollListComponent } from '@libs/ng-shared/components/scroll-list'
import { SearchbarComponent } from '@libs/ng-shared/components/searchbar/searchbar.component'
import { ScrollbarDirective } from '@libs/ng-shared/directives/scrollbar'
import { TimeAgoPipe } from '@libs/ng-shared/pipes'
import { IPaginatedListRes } from '@libs/payload'

import { EDITOR_API } from '../../services'
import { UploadData } from '../upload/upload.type'

@Component({
  selector: 'ace-replace-with-upload',
  standalone: true,
  imports: [
    CommonModule,
    MatIcon,
    SearchbarComponent,
    MatRadioButton,
    MatButton,
    ScrollbarDirective,
    MatIconButton,
    ScrollListComponent,
    MatRipple,
    TimeAgoPipe
  ],
  template: `
    <div class="w-150 rounded-lg bg-white">
      <div class="border-tertiary-100 flex h-12 w-full flex-shrink-0 items-center justify-between border-b border-solid px-4">
        <span class="text-tertiary-900 text-lg font-normal ">已上传数据</span>
        <button mat-icon-button (click)="handleClose()">
          <mat-icon svgIcon="editorup:Close (关闭)" class="icon-size-4"></mat-icon>
        </button>
      </div>
      <div class="flex h-[333px] w-full flex-grow flex-col px-4">
        <!--        search uploaded-->
        <div class="mb-2 mt-4 w-full">
          <ace-searchbar [(keywords)]="keywords"></ace-searchbar>
        </div>
        <div class="relative mb-4 h-0 w-full flex-1 pt-2">
          <ace-scroll-list [loader]="uploadListLoader" [size]="60" (valueChange)="handleUploadListChange($event)">
            @for (upload of uploadList(); track upload.id) {
              <div
                mat-ripple
                (click)="handleUploadClick(upload)"
                class="h-15 group mb-2 flex w-full items-center rounded-lg px-3 transition-all"
                [class]="
                  currentSelect()?.id === upload.id
                    ? 'border-primary  bg-primary-600 border bg-opacity-10'
                    : 'hover:bg-primary-300 border border-transparent hover:bg-opacity-10'
                "
              >
                <mat-icon class="icon-size-7 mr-2 flex-shrink-0" [svgIcon]="'custom:upload-userdata'"></mat-icon>
                <div class="flex w-0 flex-grow flex-col overflow-hidden">
                  <span class="text-tertiary-900 block w-full overflow-hidden text-ellipsis text-nowrap text-lg font-medium leading-5">{{ upload.name }}</span>
                  @if (upload.createdAt) {
                    <span class="text-tertiary-600 block w-full text-sm font-normal">上传时间：{{ upload.createdAt | timeAgo }}</span>
                  }
                </div>
                @if (currentSelect()?.id === upload.id) {
                  <mat-icon class="icon-size-5 absolute right-2 flex-shrink-0" svgIcon="editorup:upload-checked"></mat-icon>
                }
              </div>
            }

            <div no-result class="flex h-full w-full flex-col items-center justify-center">
              <svg width="80" height="94.4" viewBox="0 0 50 59" fill="none">
                <rect x="7.846" y="5.414" width="37.968" height="42.431" rx="2" fill="#D1D7E7" />
                <g filter="url(#a)">
                  <rect x="3.892" y="9.377" width="37.968" height="42.431" rx="2" fill="url(#b)" />
                  <rect x="4.142" y="9.627" width="37.468" height="41.931" rx="1.75" stroke="url(#c)" stroke-width=".5" />
                </g>
                <path d="m3.892 42.762 12.546.047 1.853 3.873 11.127.027 2.03-3.865 16.952.023v11.474a4 4 0 0 1-4 4H3.892z" fill="url(#d)" />
                <g filter="url(#e)">
                  <path d="m0 42.762 13.855.047 1.853 3.873 11.127.027 2.03-3.865 15.643.023v11.474a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4z" fill="url(#f)" />
                </g>
                <path d="M17.01 51.86h9.136" stroke="#B3C0E7" stroke-width="2" stroke-linecap="round" />
                <path d="M11.835 22.381h16.757m-16.757 7.558h13.092" stroke="#CAD5F6" stroke-width="2" stroke-linecap="round" />
                <path
                  d="M33.577 31.798a.634.634 0 0 1 .937-.053l1.622 1.605a.61.61 0 0 1 .048.82.634.634 0 0 1-.937.052l-1.622-1.605a.61.61 0 0 1-.048-.82"
                  fill="#fff"
                />
                <path d="m34.461 33.833 1.302-1.203 4.036 4.38a.87.87 0 0 1-.05 1.231.9.9 0 0 1-1.271-.05z" fill="url(#g)" />
                <g filter="url(#h)" shape-rendering="crispEdges">
                  <ellipse
                    cx="8.099"
                    cy="8.091"
                    rx="8.099"
                    ry="8.091"
                    transform="matrix(.73438 -.67874 .6899 .7239 17.715 26.63)"
                    fill="url(#i)"
                    fill-opacity=".2"
                  />
                  <path
                    d="M34.459 22.171a6.965 6.965 0 0 1-.321 9.952c-2.88 2.662-7.405 2.52-10.106-.315a6.965 6.965 0 0 1 .321-9.952c2.88-2.662 7.405-2.52 10.106.315Z"
                    stroke="url(#j)"
                    stroke-width="2"
                  />
                </g>
                <ellipse cx="6.183" cy="6.177" rx="6.183" ry="6.177" transform="matrix(.73438 -.67874 .6899 .7239 20.723 26.99)" fill="url(#k)" />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="m44.51.568.631 4.66c.03.224.35.234.394.013l.947-4.66a.2.2 0 0 0-.196-.24h-1.578a.2.2 0 0 0-.198.227m3.95.97-2.688 3.878c-.128.185.104.405.287.27l3.86-2.84a.197.197 0 0 0 .015-.308l-1.172-1.036a.204.204 0 0 0-.301.036M46.765 6.02l2.793-.831a.2.2 0 0 1 .256.16l.154.937a.2.2 0 0 1-.203.23l-2.948-.104c-.226-.008-.269-.327-.052-.392"
                  fill="#6775EE"
                />
                <defs>
                  <linearGradient id="b" x1="22.876" y1="10.581" x2="22.876" y2="51.808" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#EEF1FA" />
                    <stop offset="1" stop-color="#D7E2FF" />
                  </linearGradient>
                  <linearGradient id="c" x1="22.876" y1="9.377" x2="17.005" y2="46.883" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#fff" />
                    <stop offset="1" stop-color="#fff" stop-opacity="0" />
                  </linearGradient>
                  <linearGradient id="d" x1="37.664" y1="36.466" x2="40.732" y2="69.759" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#CED9F9" />
                    <stop offset="1" stop-color="#485782" />
                  </linearGradient>
                  <linearGradient id="f" x1="21.975" y1="42.762" x2="21.975" y2="57.835" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FEFEFE" stop-opacity=".9" />
                    <stop offset="1" stop-color="#C4D0F6" />
                  </linearGradient>
                  <linearGradient id="g" x1="36.395" y1="38.853" x2="41.371" y2="36.146" gradientUnits="userSpaceOnUse">
                    <stop offset=".375" stop-color="#6775EE" />
                    <stop offset="1" stop-color="#BAC6FF" />
                  </linearGradient>
                  <linearGradient id="i" x1="11.89" y1="3.196" x2="8.98" y2="12.775" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#fff" />
                    <stop offset="1" stop-color="#fff" stop-opacity="0" />
                  </linearGradient>
                  <linearGradient id="j" x1="24.221" y1="19.874" x2="34.631" y2="33.003" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#AFBCFF" />
                    <stop offset="1" stop-color="#6775EE" />
                  </linearGradient>
                  <linearGradient id="k" x1="11.518" y1=".497" x2="1.346" y2="10.664" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#fff" stop-opacity="0" />
                    <stop offset=".741" stop-color="#fff" stop-opacity=".32" />
                  </linearGradient>
                  <filter id="a" x="3.892" y="8.777" width="39.569" height="43.631" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="1" />
                    <feGaussianBlur stdDeviation=".3" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix values="0 0 0 0 0.584314 0 0 0 0 0.643137 0 0 0 0 0.811765 0 0 0 0.25 0" />
                    <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_33325_137503" />
                    <feBlend in="SourceGraphic" in2="effect1_dropShadow_33325_137503" result="shape" />
                  </filter>
                  <filter id="e" x="-2.6" y="40.162" width="49.709" height="20.779" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feGaussianBlur in="BackgroundImageFix" stdDeviation="1.3" />
                    <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_33325_137503" />
                    <feBlend in="SourceGraphic" in2="effect1_backgroundBlur_33325_137503" result="shape" />
                  </filter>
                  <filter id="h" x="17.888" y="17.756" width="22.714" height="22.466" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feGaussianBlur in="BackgroundImageFix" stdDeviation=".2" />
                    <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_33325_137503" />
                    <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="2" />
                    <feGaussianBlur stdDeviation="1.6" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix values="0 0 0 0 0.772549 0 0 0 0 0.8 0 0 0 0 1 0 0 0 1 0" />
                    <feBlend in2="effect1_backgroundBlur_33325_137503" result="effect2_dropShadow_33325_137503" />
                    <feBlend in="SourceGraphic" in2="effect2_dropShadow_33325_137503" result="shape" />
                  </filter>
                </defs>
              </svg>
              <span class="text-tertiary-600 mt-2 text-base font-light">未搜索到结果</span>
            </div>
          </ace-scroll-list>
        </div>
      </div>
      <div class="border-tertiary-100 flex w-full justify-end border-t p-4">
        <button mat-button (click)="handleClose()">取消</button>
        <button mat-flat-button [disabled]="!currentSelect()" (click)="handleConfirm()">确定</button>
      </div>
    </div>
  `,
  styles: ``,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReplaceWithUploadComponent implements OnInit, AfterViewInit, OnDestroy {
  keywords = model<string>('')
  uploadList = signal<UploadData[]>([])
  currentSelect = signal<UploadData | undefined>(undefined)
  apiService = inject(EDITOR_API)
  aceConfirm = output<UploadData>()
  aceCancel = output()
  keywords$ = toObservable(this.keywords)

  scrollListRef = viewChild.required(ScrollListComponent)

  _subscription = new Subscription()

  uploadListLoader = () => {
    const uploadList = this.uploadList()
    const lastId = last(uploadList)?.id || ''
    return this.apiService.getUploadDataList({ keyword: this.keywords(), id: lastId })
  }

  ngOnInit() {
    console.log('ReplaceWithUploadComponent init')
  }

  ngAfterViewInit() {
    // this.scrollListRef().reset()
    this._subscription.add(
      this.keywords$.pipe(debounceTime(100)).subscribe(() => {
        this.uploadList.set([])
        this.scrollListRef().reset()
      })
    )
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe()
  }

  handleUploadClick(upload: UploadData) {
    const current = this.currentSelect()
    if (!current || current.id !== upload.id) {
      this.currentSelect.set(upload)
    } else {
      this.currentSelect.set(undefined)
    }
  }

  handleClose() {
    this.aceCancel.emit()
  }

  handleConfirm() {
    const selected = this.currentSelect()
    if (selected) {
      this.aceConfirm.emit(selected)
    }
  }

  handleUploadListChange($event: IPaginatedListRes<unknown>) {
    this.uploadList.update(
      produce(draft => {
        draft.push(...($event.list as UploadData[]))
      })
    )
  }
}
