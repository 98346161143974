import { Overlay, OverlayRef } from '@angular/cdk/overlay'
import { ComponentPortal } from '@angular/cdk/portal'
import { CommonModule } from '@angular/common'
import { ChangeDetectionStrategy, Component, computed, ElementRef, HostBinding, inject, Injector, OnDestroy, output, signal, viewChild } from '@angular/core'
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop'
import { MatIconButton } from '@angular/material/button'
import { MatIcon } from '@angular/material/icon'

import { produce } from 'immer'
import { cloneDeep } from 'lodash'

import { ChartComponent } from '@libs/editor-view'
import { ButtonComponent, DataGridComponent, ExcelData, IMenu, MenuComponent } from '@libs/ng-shared/components'
import { ISize } from '@libs/payload'

import { WorkspaceService } from '../../services'
import { StageUiStore } from '../../store/stage-ui.store'
import { ReplaceWithUploadComponent } from '../replace-with-upload'
import { isChartSetting } from '../setting/utils'
import { FileUploadService } from '../upload/upload.service'

@Component({
  selector: 'ace-chart-data-visual',
  standalone: true,
  imports: [CommonModule, MatIcon, ButtonComponent, MenuComponent, ChartComponent, DataGridComponent, MatIconButton],
  templateUrl: './chart-data-visual.component.html',
  styles: ``,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChartDataVisualComponent {
  @HostBinding('class') class = 'w-full h-full'

  workspaceService = inject(WorkspaceService)

  aceClose = output()

  resetOptions: IMenu[] = [
    { text: '从本地替换', icon: 'editorup:upload-local' },
    { text: '从上传数据替换', icon: 'editorup:upload', callback: this.replaceFromUpload.bind(this) }
  ]

  downloadOptions: IMenu[] = [
    { text: '下载至本地', icon: 'editorup:download', callback: this.handleDownloadLocal.bind(this) },
    { text: '保存数据至已上传', icon: 'upload', callback: this.handleUpload.bind(this) }
  ]

  chartSize = signal<ISize>({
    width: 400,
    height: 400
  })
  chartScale = signal(1)

  /**
   * 自己维护一个data而不从store中获取，否则每次更改会导致handsontable进行loadData时丢失redo和undo
   * 确保chartData只会被设置一次，不会被其他地方修改
   */
  chartData = signal<ExcelData[][]>([[]])
  // 保存用于重置数据
  resetData: ExcelData[][] = [[]]

  dataGridRef = viewChild.required(DataGridComponent)

  chartElement = computed(() => {
    const selectedElement = this.workspaceService.element()
    return selectedElement && isChartSetting(selectedElement) ? selectedElement : null
  })
  setting = computed(() => this.chartElement()?.setting)

  chartContainerRef = viewChild<ElementRef<HTMLDivElement>>('chartContainerRef')

  /**
   * 用于最后组件销毁时是否触发data数据更新的判断
   */
  _changed = false
  _overlay = inject(Overlay)
  _overlayRef: OverlayRef | undefined
  _replaceWithUploadPortal: ComponentPortal<ReplaceWithUploadComponent> | undefined
  _injector = inject(Injector)
  _uiStore = inject(StageUiStore)
  uploadService = inject(FileUploadService)

  constructor() {
    toObservable(this.chartContainerRef)
      .pipe(takeUntilDestroyed())
      .subscribe(container => {
        const element = this.chartElement()
        if (element && container) {
          // 仅渲染一个和宽度相同的正方形
          const { width: cw } = container.nativeElement.getBoundingClientRect()
          const { width, height } = element.size

          this.chartSize.set({ width, height })
          this.chartScale.set(width > height ? cw / width : cw / height)
          // this.setting.set(element.setting)
          // 保存一个data副本用于第一次渲染
          const data = cloneDeep(element.setting?.data[0] || [[]])
          this.resetData = cloneDeep(data)
          this.chartData.set(data)
        }
      })
  }

  handleDataChange($event: ExcelData[][]) {
    const element = this.chartElement()
    if (element) {
      if (!this._changed) {
        // 发生改变 提交一次确保可以进行回退
        this._uiStore.setSettingElement(element.id, cloneDeep(element.setting))
        this._uiStore.resetInteractingElement()
      }
      this._changed = true
      // 更新配置需触发chart setting service中的更新检测来更新相关映射配置，否则会导致右侧预览的图表不能正常预览编辑的数据
      this._uiStore.setSettingElement(
        element.id,
        produce(element.setting, draft => {
          draft.data[0] = $event
        })
      )
      this._uiStore.resetInteractingElement({ skipHistory: true })
    }
  }

  replaceFromUpload() {
    if (!this._replaceWithUploadPortal || !this._overlayRef) {
      this._overlayRef = this._overlay.create({
        hasBackdrop: true,
        backdropClass: ['bg-black', 'bg-opacity-70'],
        positionStrategy: this._overlay.position().global().centerHorizontally().centerVertically(),
        disposeOnNavigation: true
      })
      this._replaceWithUploadPortal = new ComponentPortal(ReplaceWithUploadComponent, null, this._injector)
      this._overlayRef.outsidePointerEvents().subscribe(() => {
        this._overlayRef?.detach()
      })
    }
    const componentRef = this._overlayRef.attach(this._replaceWithUploadPortal)
    componentRef.instance.aceCancel.subscribe(() => {
      this._overlayRef?.detach()
    })
    componentRef.instance.aceConfirm.subscribe(uploadData => {
      // Load Data
      console.log('upload data', uploadData)
      this._overlayRef?.detach()
      const data = JSON.parse(uploadData.raw)
      this.chartData.set(data)
      this.handleDataChange(data)
    })
  }

  handleClose() {
    this.aceClose.emit()
  }

  handleResetData() {
    this.chartData.set(this.resetData)
    this.handleDataChange(this.resetData)
    this.dataGridRef().instance.loadData(cloneDeep(this.resetData))
    this._changed = false
  }

  handleDownloadLocal() {
    this.dataGridRef().downloadExcel()
  }

  handleUpload() {
    this.workspaceService.uploadData(this.setting()?.data[0] as ExcelData[][])
  }
}
